import React from 'react'
// import { Link } from 'gatsby';
import emailImg from '../../../images/email.png'

const Newsletter = () => (
  <div className="subscribe-section">
    <div className="container">
      <div className="row">
        <div className="offset-md-5 col-md-6 text-center">
          <img src={emailImg} alt="Email" className="subsribe-envelope pb-3" />
          <h2 className="text-center">
            Get the latest news straight to your inbox!
          </h2>
          <h5 className="text-center pb-5">Enter your email to subscribe.</h5>
          <form
            action="https://emailmarketing.webriq.services/contact/add?version=2"
            data-id="oTlFAmBbIl"
            method="post"
            id="ewf_subscriptionForm_oTlFAmBbIl"
            className="Inline None Placeholders subscribe-form"
          >
            <fieldset
              style={{ border: 'none' }}
              className="webform-custominputs"
            >
              <input
                className="form-control"
                type="email"
                name="email"
                required=""
                placeholder="Your email address"
              />
              <input
                type="submit"
                name="submit"
                value="Subscribe"
                id="eesubmit"
                className="btn btn-primary gradient-btn"
              />
            </fieldset>
            <fieldset className="webform-options" style={{ border: 'none' }}>
              <input
                type="hidden"
                name="publicaccountid"
                value="978a5f0a-48a6-4e8b-b5d1-d4d1142bf19c"
              />
              <input type="hidden" name="publicformid" value="" />
              <input type="hidden" name="returnUrl" value="" />
              <input type="hidden" name="activationReturnUrl" value="" />
              <input type="hidden" name="alreadyactiveurl" value="" />
              <input type="hidden" name="activationTemplate" value="" />
              <input type="hidden" name="source" value="WebForm" />
              <input
                type="hidden"
                id="ewf_captcha"
                name="captcha"
                value="false"
              />
              <input type="hidden" name="notifyEmail" value="" />
              <button
                type="button"
                name="dismissForm"
                id="ewf_dismissForm"
                style={{ display: 'none' }}
              >
                X
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
)

export default Newsletter
