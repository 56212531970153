import React from 'react'
import { Link } from 'gatsby'
import magazineCover from '../../../images/magazine-cover.jpg'

const Magazine = ({ lists }) => (
  <div className="mag-section">
    <div className="container">
      <div className="text-center compact-content">
        <h2>The Magazine</h2>
      </div>
      {lists.map(news => (
        <div>
          {news.tags.map(tag => (
            <div>
              {tag.name === 'Featured' ? (
                <div className="row">
                  <div className="col-md-8 pt-5">
                    <div className="featured-article">
                      <div className="featured-article-heading">
                        <span className="headline-span">Featured Article</span>
                        <Link to={news.fields.slug} className="arrow-link-white">
                          <h3 className="featured-article-title py-3">
                            {news.title}
                          </h3>
                            Read More
                          <i className="fas fa-long-arrow-alt-right" />
                        </Link>
                      </div>
                      <div
                        className="featured-article-bg"
                        style={{
                          backgroundImage: `url(${news.banner.url})`,
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 pt-5 align-self-center">
                    <div className="text-center">
                      <h3>
                        Grab the
                        <br />
                        Latest Issue!
                      </h3>
                      <Link to="/">
                        <img
                          src={magazineCover}
                          className="img-fluid mag-cover"
                          alt="Magazine Cover"
                        />
                      </Link>
                      <div>
                        <button className="btn btn-primary gradient-btn" type="button">
                          Read It Now
                        </button>
                      </div>
                      <div className="mt-3">
                        <button className="btn btn-primary green-line-btn" type="button">
                          Past Issues
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      ))}
      
    </div>
  </div>
)

export default Magazine
