import React from 'react'
import { Link } from 'gatsby'

export default ({ news }) => {
  return (
    <div className="latest-news-section">
      <div className="container">
        <div className="row">
          <div className="col-md-6 align-self-center">
            <Link to={news.fields.slug}>
              <img
                className="news-image"
                src={news.banner.url}
                alt="News Banner"
              />
            </Link>
          </div>
          <div className="col-md-6 align-self-center">
            {news.tags.map(tag => (
              <Link
                to={`tags/${tag.name.replace(/\s+/g, '-').toLowerCase()}`}
                className="headline-span"
              >
                <span key={tag.id}>{tag.name}</span>
              </Link>
            ))}

            <Link to={news.fields.slug} className="news-headline">
              <h3>
                <strong>{news.title}</strong>
              </h3>
            </Link>
            <p>{news.excerpt}</p>
            <a
              className="btn btn-primary gradient-btn"
              role="button"
              href={news.fields.slug}
            >
              Read More
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
