import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts'
import HeroSlider from '../components/homesections/slider'
import LatestNews from '../components/homesections/latestnews'
import NewsList from '../components/homesections/newslist'
import Newsletter from '../components/homesections/newsletter'
// import Events from '../components/homesections/events'
import Magazine from '../components/homesections/magazine'
// import About from '../components/homesections/about'

class IndexPage extends React.Component {
  render() {
    const newslist = this.props.data.allStrapiPosts.edges.map(list => list.node)
    const news = this.props.data.strapiPosts
    // const events = this.props.data.allStrapiEvents.edges.map(
    //   event => event.node
    // )

    return (
      <Layout>
        <HeroSlider />
        <LatestNews news={news} />
        <NewsList newslist={newslist} />
        <Newsletter />
        {/*<Events events={events} />
         */}

        <Magazine lists={newslist} />
        {/*<About />*/}
      </Layout>
    )
  }
}

export default IndexPage

export const newsQuery = graphql`
  query {
    allStrapiPosts(limit: 5, sort: { fields: [published], order: DESC }) {
      edges {
        node {
          id
          title
          excerpt
          fields {
            slug
          }
          banner {
            url
          }
          categories {
            id
            name
          }
          tags {
            id
            name
          }
          published
        }
      }
    }
    strapiPosts(title: { eq: "A Beginner's Guide to Legal Medical Cannabis" }) {
      title
      excerpt
      fields {
        slug
      }
      categories {
        name
      }
      tags {
        id
        name
      }
      banner {
        url
      }
    }
    allStrapiEvents(limit: 3) {
      edges {
        node {
          id
          title
          excerpt
          location
          banner {
            id
            url
          }
          published
          fields {
            slug
          }
        }
      }
    }
  }
`
