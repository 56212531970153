import React from 'react'
import { Link } from 'gatsby'
import Moment from 'react-moment'
import ads1 from '../../../images/sample-ad-2.gif'
import ads2 from '../../../images/sample-ad-3.jpeg'

const NewsList = ({ newslist }) => (
  <div className="news-list-section">
    <div className="container">
      <div className="row">
        <div className="col-md-8">
          {newslist.map(news => (
            <div className="row news-item">
              <div key={news.id} className="col-md-5">
                <Link to={news.fields.slug}>
                  <div className="overlay">
                    <div className="thumb-date post-date" />
                  </div>
                </Link>
                <Link to={news.fields.slug}>
                  <div
                    className="news-list-image"
                    style={{
                      backgroundImage: `url(${news.banner.url})`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                    }}
                  >
                    <div className="overlay">
                      <div className="thumb-date post-date">
                        <span className="month">
                          <Moment format="MMM" date={news.createdAt} />
                        </span>
                        <span className="day">
                          <Moment format="DD" date={news.createdAt} />
                        </span>
                        <span className="year">
                          <Moment format="YYYY" date={news.createdAt} />
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-7">
                {news.tags.map(tag => (
                  <Link
                    to={`tags/${tag.name.replace(/\s+/g, '-').toLowerCase()}`}
                    className="headline-span"
                  >
                    <span key={tag.id}>{tag.name}</span>
                  </Link>
                ))}
                <Link to={news.fields.slug} className="news-headline">
                  <h4>{news.title}</h4>
                </Link>
                <p>{news.excerpt}</p>
                <Link to={news.fields.slug} className="arrow-link">
                  Read More
                  <i className="fas fa-long-arrow-alt-right" />
                </Link>
              </div>
            </div>
          ))}
        </div>
        <div className="col-md-4">
          <span className="headline-span">Trending Now</span>
          {newslist.map(news => (
            <div>
              {news.tags.map(tag => (
                <div>
                  {tag.name === 'Trending' ? (
                    <div className="row trending-now-item">
                      <div key={news.id} className="col-md-5">
                        <Link to={news.fields.slug}>
                          <div
                            className="trending-now-image"
                            style={{
                              backgroundImage: `url(${news.banner.url})`,
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center'
                            }}
                          >
                            <div className="date-trending">
                              <span className="date">
                                <Moment format="MMM DD" date={news.published} />
                                <i className="fas fa-long-arrow-alt-right" />
                              </span>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-md-7">
                        <Link to={news.fields.slug} className="news-headline">
                          <h5>{news.title}</h5>
                        </Link>
                      </div>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          ))}

          <div className="ads-img text-center">
            <img
              src={ads1}
              className="mb-4 img-fluid"
              alt="First advertisement"
            />
            <img
              src={ads2}
              className="mb-4 img-fluid"
              alt="Second advertisement"
            />
          </div>
        </div>
      </div>

      <div className="text-center">
        <Link to="/latest-articles">
          <button className="gradient-btn btn btn-primary" type="button">
            More Stories
          </button>
        </Link>
      </div>
    </div>
  </div>
)

export default NewsList
