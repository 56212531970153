import React from 'react'
import { Link } from 'gatsby';
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import bg1 from '../../../images/slide-3-bg.jpg'
import bg2 from '../../../images/leaf-3065626_1920.jpg'
import bg3 from '../../../images/coming-2019.jpg'
import bg4 from '../../../images/slide-3-bg.jpg'
import magazineMock from '../../../images/magazines-mock.png'
import emailImg from '../../../images/email.png'

class HeroSlider extends React.Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      arrows: false,
      autoplay: true,
      slidesToScroll: 1,
    }

    return (
      <Slider {...settings} className="hero-slider">
        <div>
          <div className="hero-slide slide-1">
            <div className="container">
              <div className="slide-1 slide-text">
                <h2>
                <Link to="/latest-articles" className="text-white">
                  Read Our Latest
                  <br />
                  <span>Articles</span>
                  <br />
                </Link>
                </h2>
              </div>
            </div>
            <div
              className="slide-bg"
              style={{
                backgroundImage: `url(${bg1})`,
              }}
            />
          </div>
        </div>
        <div>
          <div className="hero-slide slide-2">
            <div className="container">
              <div className="slide-2 slide-text">
                <div className="row">
                  <div className="col-md-5 col-sm-5 align-self-center">
                    <img
                      src={magazineMock}
                      alt="Magazine Mock"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-lg-7 col-sm-7 align-self-center">
                    <h5>
                      An upscale lifestyle magazine highlighting the industries
                      best events, food and culture as well as covering the
                      latest in politics, education and the law.
                      <br />
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="slide-bg"
              style={{
                backgroundImage: `url(${bg2})`,
              }}
            />
          </div>
        </div>
        <div>
          <div className="hero-slide slide-1">
            <div className="container">
              <div className="slide-1 slide-text">
                <h2>
                  <Link to="/advertise-with-us" className="text-white">
                    We Are 
                    <br />
                    <span>Canna Culture</span>
                    <br />
                  </Link>
                </h2>
              </div>
            </div>
            <div
              className="slide-bg"
              style={{
                backgroundImage: `url(${bg3})`,
              }}
            />
          </div>
        </div>
        <div>
          <div className="hero-slide slide-4">
            <div className="container">
              <div className="slide-4 slide-text">
                <img
                  src={emailImg}
                  alt="Email"
                  className="subsribe-envelope pb-3"
                />
                <h4 className="pb-3">
                  Subscribe to join the cannabis culture
                  <br />
                </h4>
                <form
            action="https://emailmarketing.webriq.services/contact/add?version=2"
            data-id="oTlFAmBbIl"
            method="post"
            id="ewf_subscriptionForm_oTlFAmBbIl"
            className="Inline None Placeholders subscribe-form"
          >
            {/*          <form className="subscribe-form">*/}
            <fieldset
              style={{ border: 'none' }}
              className="webform-custominputs"
            >
              <input
                className="form-control"
                type="email"
                name="email"
                required=""
                placeholder="Your email address"
              />
              {/*              <button
                className="btn btn-primary gradient-btn"
                type="submit"
                name="submit"
                value="Subscribe"
                id="eesubmit"
              >
                Subscribe
              </button>*/}
              <input
                type="submit"
                name="submit"
                value="Subscribe"
                id="eesubmit"
                className="btn btn-primary gradient-btn"
              />
            </fieldset>
            <fieldset className="webform-options" style={{ border: 'none' }}>
              {/*              <input
                type="submit"
                name="submit"
                value="Subscribe"
                id="eesubmit"
              />*/}
              <input
                type="hidden"
                name="publicaccountid"
                value="978a5f0a-48a6-4e8b-b5d1-d4d1142bf19c"
              />
              <input type="hidden" name="publicformid" value="" />
              <input type="hidden" name="returnUrl" value="" />
              <input type="hidden" name="activationReturnUrl" value="" />
              <input type="hidden" name="alreadyactiveurl" value="" />
              <input type="hidden" name="activationTemplate" value="" />
              <input type="hidden" name="source" value="WebForm" />
              <input
                type="hidden"
                id="ewf_captcha"
                name="captcha"
                value="false"
              />
              <input type="hidden" name="notifyEmail" value="" />
              <button
                type="button"
                name="dismissForm"
                id="ewf_dismissForm"
                style={{ display: 'none' }}
              >
                X
              </button>
            </fieldset>
          </form>
              </div>
            </div>
            <div
              className="slide-bg"
              style={{
                backgroundImage: `url(${bg4})`,
              }}
            />
          </div>
        </div>
      </Slider>
    )
  }
}

export default HeroSlider
